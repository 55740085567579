import React from "react";
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper';

SwiperCore.use([Navigation, Pagination]);

const RelutantCompany = (props) => {
    return (
        <div className="flex flex-col w-11/12 text-left m-auto px-5">
            <div className="relative">
                <div className="w-24 h-24 absolute rounded-full bg-circle-pink bg-opacity-60 left-56 top-10 items-baseline -z-10"></div>
            </div>
            <p className="uppercase w-full  text-4xl  font-body mt-20">the reluctant</p>
            <p className="my-4 w-full  text-4xl sm:text-6xl font-bold  font-body text-text-red">WINE COMPANY</p>
            <p className="my-4 w-full text-2xl font-body">If we had it our way,<br />
                we would not sell any of the bottles we buy and would just stockpile it all for the impending wine-ageddon.
            </p>
            <p className="my-4 w-full text-2xl font-body">As such our mission is to find the wines that we would be  <span className="font-bold">most happy drinking</span> in our underground cellar-bunkers.
                When Capitalism finally fails and nuclear winter befalls us, it will be about what truly matters
                <span className="font-bold">- taste and quality,</span> not fancy labels.
            </p>

            <p className="my-4 w-full text-2xl  font-body">So join us in our quest to discover tasty wines and start your own wine-ageddon collection.</p>
            <div className="m-auto  p-20 sm:p-5">
                <div className="w-3 h-3 rounded-full bg-red hover:bg-rose-400 mx-6"></div>
                <div className="w-7 h-7 rounded-full bg-red hover:bg-rose-400"></div>
            </div>
            {/* <div className="relative">
                <div className="w-16 h-16 absolute rounded-full bg-circle-pink bg-opacity-60 right-2 bottom-2 -z-20 mb-3"></div>
                <div className="w-48 h-48 absolute rounded-full bg-circle-pink  -right-40 -bottom-40  -z-20 m-0 p-0"></div>
            </div> */}
        </div >)

}

export default RelutantCompany