import React from "react";
const WhoWeAre = (props) => {
    return (
        <div className="bg-whoweare bg-center bg-no-repeat bg-local bg-cover flex flex-col justify-center items-center min-h-screen" id="whoweare">
            <p className="uppercase w-full h-full font-black text-5xl sm:text-9xl  text-pale text-center cursor-pointer">WHO WE ARE</p>
            <div className="m-auto my-9">
                <div className="w-3 h-3 rounded-full bg-pale hover:bg-rose-600 mx-6"></div>
                <div className="w-7 h-7 rounded-full bg-pale hover:bg-rose-600"></div>
            </div>
        </div>
    )

}

export default WhoWeAre