import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import RelutantCompany from "./ReluctantCompany";
import Community from "./Community";
import CCommerce from "./C-Commerce";
import Background0 from '../../assets/backgrounds/whoweareone.png'
import Background1 from '../../assets/backgrounds/ccommerce.png'
import Background2 from '../../assets/backgrounds/community.png'
import 'swiper/css/bundle';
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper';


SwiperCore.use([Navigation, Pagination]);

const WhoWeAreCarousel = () => {
    const [slideActiveIndex, setSlideActiveIndex] = useState(0)

    return (
        <div id="whowearecaro" >
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation={true}
                pagination={true}
                style={{
                    backgroundImage: `url(${slideActiveIndex === 0 ? Background0 :
                        slideActiveIndex === 1 ? Background1 : slideActiveIndex === 2 ? Background2 : null})`
                }}
                className="bg-pale  bg-center bg-no-repeat bg-cover  min-h-screen"
                onSlideChange={(swiper) => setSlideActiveIndex(swiper.realIndex)}
            >
                <SwiperSlide className="flex flex-row items-center justify-center w-full min-h-screen">

                    <RelutantCompany />

                </SwiperSlide>
                <SwiperSlide className=" flex flex-row items-center justify-center w-full min-h-screen"><CCommerce /></SwiperSlide>
                <SwiperSlide className=" flex flex-row items-center justify-center w-full min-h-screen"><Community /></SwiperSlide>
            </Swiper >
        </div>
    )

}

export default WhoWeAreCarousel