import React from "react";
const CCommerce = (props) => {
    return (
        <div className="flex flex-col w-11/12 text-left m-auto px-5">
            <p className="uppercase w-full text-4xl sm:text-6xl  font-light  my-10 mx-auto">FROM E TO <span className="my-4 w-full text-4xl sm:text-6xl font-bold  font-body text-text-red whitespace-nowrap">C-COMMERCE</span></p>
            <p className="my-4 w-full text-2xl font-body">Frustrated with the state of E-Commerce we’ve decided to embark on a community commerce (C-Commerce) project.</p>
            <p className="my-4 w-full text-2xl font-body">Our C-Commerce project brings together wine drinkers in pursuit of amazing wines at fair pricing,
                gives them the chance to purchase these bottles directly from the wineries during our Groupbuys,
                get to know their fellow wine drinkers and meet the winemakers behind their favourite bottles.

            </p>
            <p className="my-4 w-full text-2xl font-body">Now, we know what you’re thinking - not another marketing ploy! But truth be told, we’ve tried it all - website,
                Facebook, Instagram -
                none of which worked for the simple reason that it prevented us from getting to know our community personally.


            </p>
            <p className="my-4 w-full text-2xl  font-body">So we turned up our noses at traditional marketing and decided to focus on building our community on Telegram.
                What started out as just family and friends, but has since grown to include friends of friends of friends, and
                the winemakers themselves!
            </p>

            <p className="my-4 w-full text-2xl  font-body">So come meet us, try the wines and decide for yourself if you’d like to be a part of our community.</p>
            <div className="m-auto p-20 sm:p-5">
                <div className="w-3 h-3 rounded-full bg-red hover:bg-rose-400 mx-6"></div>
                <div className="w-7 h-7 rounded-full bg-red hover:bg-rose-400"></div>
            </div>
        </div>


    )

}

export default CCommerce