import React from "react";
import logo from '../../assets/winewithusrednobg.png'
const LandingPage = (props) => {
  return (
    <div className="bg-center bg-no-repeat bg-fixed bg-cover bg-pale flex w-full min-h-screen" id="landing">
      <div className="container flex flex-wrap flex-row items-center m-auto justify-center">
        <div className="w-full md:w-2/5 py-0 text-center">
          <img className="w-full md:w-4/5 z-20" src={logo} />
        </div>
        <div className="flex flex-col w-full md:w-3/5 text-center">
          <p className="uppercase w-full  text-5xl ">WELCOME TO</p>
          <p className="my-4 w-full text-7xl font-bold  text-text-red">WINE WITH US</p>
          <p className="w-full mb-8  font-body text-4xl">an anti-ecommerce website</p>
          <div className="m-auto">
            <div className="w-3 h-3 rounded-full bg-red hover:bg-rose-400 mx-6"></div>
            <div className="w-7 h-7 rounded-full bg-red hover:bg-rose-400"></div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default LandingPage