import React from "react";
import pastgpBuyLogo1 from '../../assets/pastgpBuyLogo1.png'
import pastgpBuyBottles1 from '../../assets/pastgpBuyBottles1.png'
import pastgpBuyLogo2 from '../../assets/pastgpBuyLogo2.png'
import pastgpBuyBottles2 from '../../assets/pastgpBuyBottles2.png'
import pastgpBuyLogo3 from '../../assets/pastgpBuyLogo3.png'
import pastgpBuyBottles3 from '../../assets/pastgpBuyBottles3.png'
import pastgpBuyLogo4 from '../../assets/pastgpBuyLogo4.png'
import pastgpBuyBottles4 from '../../assets/pastgpBuyBottles4.png'

const PastGroupBuy = () => {
    return (
        <div className="flex flex-col w-11/12 text-left m-auto px-5">
            <p className="uppercase w-full text-6xl font-thin my-10 text-center">Past <span className="font-semibold">Groupbuys</span></p>
            <div className="m-auto ">
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 my-5">
                <div>
                    <img src={pastgpBuyLogo1} alt="costelLogo" className="sm:w-7/12 m-auto p-8" />
                    <img src={pastgpBuyBottles1} alt="costelBottles" className="sm:w-10/12  m-auto p-0" />
                </div>
                <div>
                    <img src={pastgpBuyLogo2} alt="vondelingLogo" className="sm:w-7/12  m-auto p-8" />
                    <img src={pastgpBuyBottles2} alt="vondelingBottles" className="w-full  m-auto pt-5" />
                </div>
                <div>
                    <img src={pastgpBuyLogo3} alt="losadaLogo" className="sm:w-7/12  m-auto p-8" />
                    <img src={pastgpBuyBottles3} alt="losadaBottles" className="w-11/12   m-auto pt-5" />
                </div>
                <div>
                    <img src={pastgpBuyLogo4} alt="almaLogo" className="sm:w-7/12  m-auto p-8" />
                    <img src={pastgpBuyBottles4} alt="pagoBottles" className="w-10/12  m-auto pt-5" />
                </div>

            </div>
            <div className="m-auto my-6 sm:my-0 ">
                <button onClick={() => {
                    window.open("https://t.me/joinchat/XWnxcICur_s4MDU1", "_blank");
                }}
                    className="text-white   py-4 px-8  rounded-full md:text-4xl text-1xl bg-red hover:bg-rose-600 duration-200">
                    BUY NOW
                </button>
            </div>
        </div>

    )

}

export default PastGroupBuy