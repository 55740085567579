import React from "react";
const CoperateWineGift = (props) => {
    return (
        // <div className="bg-pale  bg-center bg-no-repeat bg-cover  flex flex-row items-center justify-center w-full min-h-screen">
        <div className="flex flex-col w-11/12 text-left m-auto px-5">
            <p className="uppercase w-full  text-5xl sm:text-6xl  my-10 font-semibold">CORPORATE WINE GIFTS</p>
            <p className="uppercase w-full  text-4xl  my-10 font-semibold">WHY CHOOSE <span className="text-text-red font-bold">WINE WITH US</span></p>

            <p className="font-semibold text-2xl">TASTE, NOT BRAND</p>
            <p className="mt-2 mb-8 w-full text-2xl font-body">
                We pride ourselves in having the <span className="text-text-red font-semibold">best tasting portfolio </span> in Singapore, making these great gifts for all wine lovers.
            </p>

            <p className="font-semibold text-2xl">
                WINERIES, NO MIDDLEMEN
            </p>

            <p className="mt-2 mb-8 w-full text-2xl font-body">
                We know the vineyards and the winemakers, so you get the <span className="text-text-red font-semibold">best pricing </span>on our wines <span className="text-text-red font-semibold">direct from the winery.</span>
            </p>
            <p className="font-semibold text-2xl">
                CUSTOMISATION
            </p>
            <p className="mt-2 mb-8 w-full text-2xl font-body">
                We offer customization of labels so you can include your company logo or a personalized message
                to mark a special occasion, making these bottles even more special.
            </p>
            <div className="m-auto my-6 sm:my-0">
                <button onClick={() => {
                    window.open("https://t.me/joinchat/XWnxcICur_s4MDU1", "_blank");
                }}
                    className="text-white   py-4 px-8  rounded-full md:text-4xl text-1xl bg-red hover:bg-rose-600 duration-200">
                    MORE INFORMATION
                </button>
            </div>
        </div>
        // </div>
    )

}

export default CoperateWineGift