import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import DirectGroupbuy from "./DirectGroupbuy";
import CurrentGroupbuy from './CurrentGroupbuy'
import CoperateWineGift from './CoperateWineGift'
import PastGroupBuy from "./PastGroupbuy";
import 'swiper/css/bundle';
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper';

import Background2 from '../../assets/backgrounds/whatwedopage1.png'
import Background3 from '../../assets/backgrounds/whatwedopage2.png'
import Background0 from '../../assets/backgrounds/whatwedopage3.png'
import Background1 from '../../assets/backgrounds/whatwedopage4.png'

SwiperCore.use([Navigation, Pagination]);

const WhatWeDoCarousel = () => {
    const [slideActiveIndex, setSlideActiveIndex] = useState(0)
    return (
        <div id="whatwedocaro" >
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                navigation={true}
                pagination={true}
                className="bg-pale  bg-center bg-no-repeat bg-cover  min-h-screen"
                style={{
                    backgroundImage: `url(${slideActiveIndex === 0 ? Background0 :
                        slideActiveIndex === 1 ? Background1 : slideActiveIndex === 2 ? Background2 : slideActiveIndex === 3 ? Background3 : null})`
                }}
                onSlideChange={(swiper) => setSlideActiveIndex(swiper.realIndex)}
            >
                <SwiperSlide className="flex flex-row items-center justify-center w-full min-h-screen"><DirectGroupbuy /></SwiperSlide>

                <SwiperSlide className="flex flex-row items-center justify-center w-full min-h-screen"><CurrentGroupbuy /></SwiperSlide>
                {/* <SwiperSlide className="flex flex-row items-center justify-center w-full min-h-screen"><PastGroupBuy /></SwiperSlide> */}
                <SwiperSlide className="flex flex-col items-center justify-center  min-h-screen">
                    <CoperateWineGift />
                </SwiperSlide>

            </Swiper >
        </div>
    )

}

// className="bg-whatwedoOne bg-pale  bg-center bg-no-repeat bg-cover  flex flex-row items-center justify-center w-full min-h-screen"
// className="bg-whatwedoOne bg-pale  bg-center bg-no-repeat bg-cover  flex flex-row items-center justify-center w-full min-h-screen"
// className="bg-whatwedoOne bg-pale  bg-center bg-no-repeat bg-cover  flex flex-row items-center justify-center w-full min-h-screen"
// className="bg-whatwedoOne bg-pale  bg-center bg-no-repeat bg-cover  flex flex-row items-center justify-center w-full min-h-screen"

export default WhatWeDoCarousel