import React from "react";
const WhatWeDo = (props) => {
    return (
        <div className="bg-whoweare bg-center bg-no-repeat bg-local bg-cover bg-red flex flex-col justify-center items-center min-h-screen" id="whatwedo">
            <p className="uppercase w-4/10 h-full font-black  text-5xl sm:text-9xl text-pale text-center cursor-pointer">WHAT WE DO</p>
            <div className="m-auto my-9">
                <div className="w-3 h-3 rounded-full bg-pale hover:bg-rose-600 mx-6"></div>
                <div className="w-7 h-7 rounded-full bg-pale hover:bg-rose-600"></div>
            </div>
        </div>
    )

}

export default WhatWeDo