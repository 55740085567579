import React from "react";
import prosecco from '../../assets/backgrounds/event.png'
const WineWithUsEvent = (props) => {
    return (
        // <div className="bg-event bg-center bg-no-repeat bg-local bg-cover flex flex-col justify-center items-center min-h-screen" id="events">
        // </div>
        <div className="flex justify-center items-center h-screen bg-black" id="events">
            <img src={prosecco} alt="event" className="max-w-full max-h-full" />
        </div>
    )

}

export default WineWithUsEvent