import React from "react";
import WineWithUs from '../../assets/winewithupalebg.png'
import QR from '../../assets/QR.png'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEnvelope,  } from "@fortawesome/free-regular-svg-icons";

const Contact = (props) => {
    return (
        <div className="flex items-end justify-center  bg-pale" id="contact">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2  bg-red py-20 w-full" >
                <div className="">
                    <img src={WineWithUs} alt="winewithus" width={700} />
                </div>
                <div className="m-auto pt-0">
                    <p className="text-4xl sm:text-5xl py-2 text-white">CONTACT</p>
                    <div className="w-20 border-t border-white border-y-8 my-4 pl-2"></div>
                    <p className="text-2xl py-2 hover:text-black text-white cursor-pointer" onClick={() => {
                        window.open("mailto:hello@winewithus.io", "_blank");
                    }}><span className="pr-3"><i className="fa fa-envelope-o" aria-hidden="true"></i>
                        </span>hello@winewithus.io</p>
                    <p className="text-2xl py-2 hover:text-black text-white cursor-pointer" onClick={() => {
                        window.open("https://www.facebook.com/winewithussg", "_blank");
                    }} ><span className="pr-3"><i className="fa fa-facebook-official" aria-hidden="true"></i></span>/winewithussg</p>
                    <p className="text-2xl py-2 hover:text-black text-white cursor-pointer" onClick={() => {
                        window.open("https://www.instagram.com/winewithussg/", "_blank");
                    }}>
                        <span className="pr-3"><i className="fa fa-instagram" aria-hidden="true"></i></span>@winewithussg</p>
                    <p className="text-2xl py-2 hover:text-black text-white cursor-pointer" onClick={() => {
                        window.open("https://goo.gl/maps/F4euv3hyfqSVzEjE7", "_blank");
                    }}><span className="pr-3"><i className="fa fa-map-marker" aria-hidden="true"></i></span>Singapore</p>
                </div>
                <div className="m-auto">
                    <img src={QR} alt="winewithus" width={300} className="m-auto" />
                    <button onClick={() => {
                        window.open("https://t.me/joinchat/XWnxcICur_s4MDU1", "_blank");
                    }}
                        className="text-white   py-4 px-4  rounded-full md:text-4xl text-2xl bg-black hover:bg-gray-900 hover:opacity-90 duration-100 ">
                        JOIN OUR COMMUNITY
                    </button>
                </div>
            </div>
        </div >
        // </div >
    )
}

export default Contact