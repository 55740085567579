import React from "react";
import LongButton from "../../components/LongButton";
const Community = (props) => {
    return (
        // <div className="bg-pale sm:bg-ccommerce bg-center bg-no-repeat bg-fixed  flex flex-row items-center justify-center min-h-screen my-4">
        <div className="flex flex-col  text-left m-auto px-10 sm:px-20">
            <p className="uppercase w-full  text-4xl sm:text-6xl font-light my-10">ABOUT THE  <span className="my-0 w-full text-4xl sm:text-6xl font-bold  font-body text-text-red whitespace-nowrap">WINE WITH US</span> <br /> COMMUNITY</p>

            <p className="font-semibold text-2xl">TASTE, NOT BRAND</p>
            <p className="mt-2 mb-6 w-full text-2xl font-body">
                We are our best customers, <span className="text-text-red">we drink what we sell</span>, and we pride ourselves on having the <span className="text-text-red ">best tasting portfolio in Singapore.</span>
            </p>
            <p className="font-semibold text-2xl">
                PEOPLE, NOT PROFITS
            </p>

            <p className="mt-2  mb-6 w-full text-2xl font-body">
                Building trust is essential to growing a community. Our customers trust our tastes, our recommendations and our pricing.
            </p>
            <p className="font-semibold text-2xl">
                WINERIES, NO MIDDLEMEN
            </p>

            <p className="mt-2  mb-6 w-full text-2xl font-body">
                We know the vineyards, we know the winemakers and we introduce them to our community.           </p>
            <p className="font-semibold text-2xl">
                LOWEST PRICE GUARANTEE
            </p>
            <p className="mt-2  mb-6 w-full text-2xl font-body">
                All the bottles on our Community Pricelist come with a LOWEST PRICE GUARANTEE. If you find the same label
                and vintage cheaper within 30 days of purchasing from us, we will refund you the difference! T&Cs apply.
            </p>
            <div className="m-auto my-6 sm:my-0 ">
                <button onClick={() => {
                    window.open("https://t.me/joinchat/XWnxcICur_s4MDU1", "_blank");
                }}
                    className="text-white   py-4 px-8  rounded-full md:text-4xl text-1xl bg-red hover:bg-rose-600 duration-200">
                    JOIN OUR COMMUNITY
                </button>
            </div>
        </div>

        // </div >

    )

}

export default Community