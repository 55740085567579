import React from "react";
import Represet from '../../assets/represent-gp.png'
const DirectGroupby = (props) => {
    return (
        <div className="flex flex-col items-center m-auto px-5 bg-pale min-h-screen z-0 py-4 overflow-hidden" id="joinourcommunity">
            <div className="flex flex-col w-11/12 m-auto pt-20">
                <div className="relative ">
                    <div className="w-24 h-24 sm:w-36 sm:h-36 absolute rounded-full bg-circle-pink items-baseline   z-10 left-3/4 sm:left-32rem top-2 "></div>
                </div>
                <p className="uppercase text-4xl sm:text-6xl  mt-10 font-semibold my-4 z-20">WHO WE REPRESENT</p>
                <img src={Represet} className="w-full  " />
            </div>

        </div >

    )

}
export default DirectGroupby