import React, { useState } from "react";
import LandingPage from './screens/landingPage/LandingPage.js';
import WhoWeAre from './screens/whoWeAre/WhoWeAre';
import WhatWeDo from './screens/whatWeDo/WhatWeDo';
import WhatWeDoCaro from './screens/whatwedocarousel/'
import WhoWeAreCarousel from './screens/whoWeAreCarousel'
import WhoWeRepresent from './screens/joinOurCommunity/WhoWeRepresent'
import Contact from './screens/joinOurCommunity/Contact'
import WineWithUsEvent from './screens/events/WineWithUsEvent.js'
import NavBar from './components/NavBar';

function App() {
  return (
    <div>
      <NavBar />
      <LandingPage />
      <WhoWeAre />
      <WhoWeAreCarousel />
      <WhatWeDo />
      <WhatWeDoCaro />
      <WineWithUsEvent/>
      <WhoWeRepresent />
      <Contact />
    </div>

  );
}

export default App;
