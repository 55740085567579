import React, { useState, useRef } from "react";
import { Transition } from "@headlessui/react";
import NavLogo from '../assets/NavLogo.png'
import NavLogoRed from '../assets/NavLogoRed.png'
import { Link } from 'react-scroll'
const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activePage, setActivePage] = useState('')
    const ref = useRef(null)
    const handleSetActive = (to) => {
        setActivePage(to)
    }
    console.log("Active Page -->", activePage);
    return (
        <nav className="bg-transparent fixed top-0 left-0 right-0 z-40 scroll-py-40">
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex w-full h-full items-center justify-between flex-row">
                        <Link
                            to="landing"
                            className="flex-grow-1 cursor-pointer"
                            spy={true}
                            smooth offset={-10}
                            onSetActive={handleSetActive}>
                            {activePage !== "landing" &&
                                <img
                                    className="h-9 w-9 sm:h-16 sm:w-16 "
                                    src={(activePage === "whoweare" || activePage === "whatwedo") ? NavLogo : NavLogoRed}
                                    alt="WINE WITH US"
                                />
                            }

                        </Link>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-8 font-muliBold">
                                <Link
                                    to="whoweare" spy={true} smooth offset={8}
                                    className={`cursor-pointer ${activePage === 'whoweare' ? `text-pale` : activePage === 'whowearecaro' ? `text-text-red` : activePage === 'events' ? `text-white` : `text-black`}`}
                                    onSetActive={handleSetActive}>
                                    WHO WE ARE
                                </Link>
                                <Link to="whowearecaro" spy={true} smooth offset={-10} className="cursor-pointer" onSetActive={handleSetActive}></Link>
                                <Link to="whatwedo" spy={true} smooth offset={0}
                                    className={`cursor-pointer ${activePage === 'whatwedo' ? `text-pale` : activePage === 'whatwedocaro' ? `text-text-red` : activePage === 'events' ? `text-white` : `text-black`}`}
                                    onSetActive={handleSetActive}>WHAT WE DO</Link>
                                <Link to="whatwedocaro" spy={true} smooth offset={-10} className="cursor-pointer" onSetActive={handleSetActive}></Link>
                                <Link to="events" spy={true} smooth offset={-3} className={`cursor-pointer ${activePage === 'events' ? `text-pinky-pale` : `text-black`} `} onSetActive={handleSetActive}>EVENT</Link>
                                <Link to="joinourcommunity" spy={true} smooth offset={8} className={`cursor-pointer ${activePage === 'joinourcommunity' ? `text-text-red` : activePage === 'contact' ? `text-white` : activePage === 'events' ? `text-white` : `text-black`} `} onSetActive={handleSetActive}>JOIN OUR COMMUNITY</Link>
                                <Link to="contact" spy={true} smooth offset={8} onSetActive={handleSetActive}></Link>
                            </div>
                        </div>
                    </div>
                    <div className="-mr-2 pr-2 flex  md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen ? (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                {/* {(ref) => ( */}
                <div ref={ref} className="md:hidden bg-gray-900 bg-opacity-90" id="mobile-menu">
                    <div className="px-2 pt-8 pb-3 space-y-1 sm:px-3">
                        <Link to="whoweare" spy={true} smooth offset={8} className="text-gray-100 bg-opacity-100 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
                            onClick={() => setIsOpen(!isOpen)}
                        >WHO WE ARE</Link>
                        <Link to="whatwedo" spy={true} smooth offset={8} className="text-gray-100  bg-opacity-100 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
                            onClick={() => setIsOpen(!isOpen)}>WHAT WE DO</Link>
                        <Link to="events" spy={true} smooth offset={8} className="text-gray-100 bg-opacity-100 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
                            onClick={() => setIsOpen(!isOpen)}>EVENT</Link>
                        <Link to="joinourcommunity" spy={true} smooth offset={8} className="text-gray-100 bg-opacity-100 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
                            onClick={() => setIsOpen(!isOpen)}>JOIN OUR COMMUNITY</Link>



                    </div>
                </div>
                {/* )} */}
            </Transition>
        </nav>


    );
}

export default NavBar;



