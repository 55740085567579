import React from "react";
const DirectGroupby = (props) => {
    return (
        <div className="flex flex-col w-11/12 text-left m-auto px-5">
            <p className="uppercase w-full  text-5xl sm:text-6xl  my-10 font-semibold">Winery Direct Groupbuy</p>
            <p className="mt-2 mb-8 w-full text-2xl font-body">
                We search tirelessly for the wines we love, and the wineries we want to work with -
                Cutting out the middlemen and giving you access to <span className="text-text-red font-semibold">Winery Direct Pricing!</span>
            </p>


            <p className="mt-2 mb-8 w-full text-2xl font-body">
                Every month, we organise monthly Groupbuys in our Telegram community giving them a chance to preorder the new wines we are bringing in at a significant discount before they arrive in Singapore.
            </p>

            <p className="mt-2 mb-8 w-full text-2xl font-body">
                The only catch? Payment in advance before the wines arrive!
            </p>

            <p className="mt-2 mb-8 w-full text-2xl font-body">
                <span className="text-text-red font-semibold">PRICE CHOPE:</span> Order 6 bottles of wine during our Winery Direct Groupbuy and lock-in the offer price of your lowest-priced bottle for 3 months from the date of delivery. T&Cs apply.
            </p>

            <p className="mt-2 mb-8 w-full text-2xl font-body">
                Join our Telegram community to participate in our next Winery Direct Groupbuy!
            </p>
            <div className="m-auto my-6 sm:my-0 ">
                <button onClick={() => {
                    window.open("https://t.me/joinchat/XWnxcICur_s4MDU1", "_blank");
                }}
                    className="text-white   py-4 px-8  rounded-full md:text-4xl text-1xl bg-red hover:bg-rose-600 duration-200">
                    JOIN OUR COMMUNITY
                </button>
            </div>
        </div>


    )

}

export default DirectGroupby