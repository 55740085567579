import React from "react";
import LongButton from "../../components/LongButton";
// import spielmannLogo from '../../assets/spielmannEstatesLogo.png'

import currentGroupProfile from '../../assets/currentGroupProfile.png'
import currentGpBuy9 from '../../assets/currentGroupBuy9.png'
import currentGpBuy8 from '../../assets/currentGroupBuy8.png'
import currentGpBuy7 from '../../assets/currentGroupBuy7.png'
import currentGpBuy6 from '../../assets/currentGroupBuy6.png'
import currentGpBuy5 from '../../assets/currentGroupBuy5.png'
import currentGpBuy4 from '../../assets/currentGroupBuy4.png'
import currentGpBuy3 from '../../assets/currentGroupBuy3.png'
import currentGpBuy2 from '../../assets/currentGroupBuy2.png'
import currentGpBuy1 from '../../assets/currentGroupBuy1.png'

const DirectGroupby = (props) => {
    return (
        <div className="flex flex-col w-11/12 text-left m-auto px-5">
            <p className="uppercase w-full text-6xl font-thin mt-10 text-center ">Current <span className="font-semibold">Groupbuy</span></p>
            <div className="m-auto ">
                <img src={currentGroupProfile} width={200} alt="spielmannLogo" />
            </div>
            <div className="grid grid-cols-3 sm:grid-cols-9 gap-0  mb-5 mx-0 sm:mx-20">
                <img src={currentGpBuy1} alt="RimartsCava" className="w-10/12 p-1" />
                <img src={currentGpBuy2} alt="RimartsCava" className="w-10/12  " />
                <img src={currentGpBuy3} alt="RimartsCava" className="w-8/12  " />
                <img src={currentGpBuy4} alt="RimartsCava" className="w-8/12  " />
                <img src={currentGpBuy5} alt="RimartsCava" className="w-8/12  " />
                <img src={currentGpBuy6} alt="RimartsCava" className="w-8/12 mt-3" />
                <img src={currentGpBuy7} alt="RimartsCava" className="w-8/12  " />
                <img src={currentGpBuy8} alt="RimartsCava" className="w-8/12  " />
                <img src={currentGpBuy9} alt="RimartsCava" className="w-8/12  " />

            </div>
            <div className="m-auto my-6 sm:my-0 ">
                <button onClick={() => {
                    window.open("https://t.me/joinchat/XWnxcICur_s4MDU1", "_blank");
                }}
                    className="text-white   py-4 px-8  rounded-full md:text-4xl text-1xl bg-red hover:bg-rose-600 duration-200">
                    BUY NOW
                </button>
            </div>
        </div>

    )

}

export default DirectGroupby